<template>
  <div>
    <router-link class="button" :class="buttonClass" :to="href" v-if="href&&!target">
      <slot></slot>
    </router-link>
    <Button :type="type" :href="href" :target="target" v-else>
      <slot></slot>
    </Button>
  </div>
</template>

<script>

export default {
  name: "dj-button",
  props: {
    type: { // 按钮样式，line-线框，dark-深色按钮，light-浅色按钮
      type: String,
      default: "line"
    },
    href: { // 按钮的链接
      type: String,
      default: null
    },
    target: {
      type: String,
      default: null
    }
  },
  data() {
    return {};
  },
  computed: {
    buttonClass() {
      let className = "";
      switch (this.type) {
        case "line":
          className = "button_line";
          break;
        case "dark":
          className = "button_dark";
          break;
        case "light":
          className = "button_light";
          break;
        default:
          className = "button_line";
          break;
      }
      return className;
    }
  },
  created() {
  },
  methods: {}
};
</script>

<style scoped lang="scss">
@import "./button.scss";
</style>

let routes = [
    //用户服务-常见问题
    {
        path: '/userService/question',
        name: 'userServiceQuestion',
        component: () => import('@/views/userService/question.vue'),
    },
    //用户服务-常见问题-问题详情
    {
        path: '/userService/question/details',
        name: 'userServiceQuestionDetails',
        component: () => import('@/views/userService/questionDetails.vue'),
    },
    //用户服务-操作手册为空
    {
        path: '/userService/manualNot',
        name: 'userServiceManualNot',
        component: () => import('@/views/userService/manualNot.vue'),
    },
];

export default routes;

import Vue from 'vue';
import './api/axios';
import App from '@/components/layout/layout';
import router from './router';
import store from './store';
import MetaInfo from 'vue-meta-info';

Vue.use(MetaInfo);
import '@/assets/css/common.scss';
import '@/utils/rem.js';

import plugins from "@/components/common/index.js";

Vue.use(plugins);

import "@/assets/iconfont/iconfont.css";

// 引入神策
import sensors from "@/utils/sensors/sensors.js";
Vue.prototype.$sensors = sensors;

Vue.config.productionTip = false;

// 引入animate动画
import animated from "animate.css";
Vue.use(animated);

new Vue({
    router,
    store,
    render: h => h(App),
    mounted() {
        document.dispatchEvent(new Event('render-event'));
    }
}).$mount('#app');

<template>
  <div class="sidebar">
    <ul class="sidebar_ul">
      <li v-if="info.sideBar.freeUse===1">
        <router-link to="/product/freeUse?source=sidebar">
          <i class="font_family icon-floatbar_shiyong"></i>
          <p>免费试用</p>
        </router-link>
      </li>
      <li v-if="info.sideBar.tryNow===1">
        <a :href="info.sideBar.tryLink" target="_blank">
          <i class="font_family icon-floatbar_tiyan"></i>
          <p>立即体验</p>
        </a>
      </li>
      <li class="download_box" v-if="info.sideBar.downloadNow===1">
        <i class="font_family icon-floatbar_xiazai"></i>
        <p>立即下载</p>
        <div class="download">
          <img :src="utils.setOssUrl(info.sideBar.downloadPic)" alt="">
          <span>扫码下载</span>
        </div>
      </li>
      <li class="contact_box" v-if="info.sideBar.contactUs===1">
        <i class="font_family icon-floatbar_lianxi"></i>
        <p>联系我们</p>
        <div class="contact">
          <span v-if="info.sideBar.contact">{{ info.sideBar.contact }}</span>
          <span v-if="info.sideBar.content">{{ info.sideBar.content }}</span>
          <img v-if="info.sideBar.contactPicture" :src="utils.setOssUrl(info.sideBar.contactPicture)" alt="">
        </div>
      </li>
      <li v-if="info.sideBar.onlineConsult===1">
        <a :href="info.sideBar.consultLink" target="_blank" @click="consult">
          <i class="font_family icon-floatbar_kefu"></i>
          <p>咨询客服</p>
        </a>
      </li>
      <li class="share_box">
        <i class="font_family icon-floatbar_fenxiang"></i>
        <p>分享页面</p>
        <div class="share">
          <span class="copy" @click="copyUrl">点击复制链接</span>
          <div id="qrCode" ref="qrCodeDiv"></div>
          <span class="code">或扫码分享</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import utils from "../../utils/utils";
import QRCode from 'qrcodejs2';

export default {
  name: "sidebar",
  props: {
    info: {
      type: Object,
    }
  },
  watch: {
    $route() {
      this.setQrCode();
    }
  },
  data() {
    return {
      utils
    };
  },
  mounted() {
    this.setQrCode();
  },
  methods: {
    setQrCode() {
      // 先清除
      const codeHtml = document.getElementById("qrCode");
      codeHtml.innerHTML = "";
      // 生成qrcode
      let path = this.getUrl("qrcode");
      let qr = new QRCode(this.$refs.qrCodeDiv, {
        text: path
      });
      qr._el.title = '';
    },
    getUrl(e) {
      let url = window.location.search;
      let theRequest = {};
      if (url.indexOf("?") != -1) {
        let str = url.substr(1);
        let strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
        }
      }
      theRequest["source"] = e;
      let pathArr = [];
      for (let i in theRequest) {
        pathArr.push(`${i}=${theRequest[i]}`);
      }
      url = window.location.origin + window.location.pathname + "?" + pathArr.join("&");
      return url;
    },
    copyUrl() {
      // 神策埋点-分享
      this.$sensors.officialSite_Share({
        officialSite_e_title: document.title
      });
      let path = this.getUrl("copyurl");
      utils.copy(`${document.title}_${this.$store.getters.getProductBasicInfo.description}_${path}`);
      this.$message("链接复制成功");
    },
    consult(){
      // 在线咨询埋点
      this.$sensors.officialSite_Consult({
        officialSite_e_title:document.title
      })
    }
  }
};
</script>

<style scoped lang="scss">
.sidebar {
  display: none;
}
@media (min-width: 750px) and (max-width: 960px) {
}
@media (min-width: 961px) {
  .sidebar {
    display: block;
    position: fixed;
    z-index: 10;
    right: 21px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    background: #FFFFFF;
    box-shadow: 0px 4px 12px 0px rgba(127, 133, 141, 0.13);
    .sidebar_ul {
      display: flex;
      flex-direction: column;
      li {
        border-bottom: 1px solid #F5F5F5;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 8px 10px;
        cursor: pointer;
        position: relative;
        .download {
          display: none;
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          left: -126px;
          width: 116px;
          height: 134px;
          background: #FFFFFF;
          box-shadow: 0px 4px 12px 0px rgba(127, 133, 141, 0.13);
          align-items: center;
          justify-content: center;
          flex-direction: column;
          &:after {
            content: "";
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            right: -4px;
            height: 0px;
            width: 0px;
            border-top: 4px solid transparent;
            border-left: 4px solid #FFF;
            border-bottom: 4px solid transparent;
          }
          img {
            width: 100px;
            height: 100px;
          }
          span {
            font-size: 14px;
            color: #666666;
            //line-height: 13px;
            margin-top: 4px;
          }
        }
        .contact {
          display: none;
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          left: -126px;
          width: 116px;
          background: #FFFFFF;
          box-shadow: 0px 4px 12px 0px rgba(127, 133, 141, 0.13);
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 8px;
          &:after {
            content: "";
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            right: -4px;
            height: 0px;
            width: 0px;
            border-top: 4px solid transparent;
            border-left: 4px solid #FFF;
            border-bottom: 4px solid transparent;
          }
          img {
            width: 100px;
            height: 100px;
            margin-top: 4px;
          }
          span {
            font-size: 14px;
            color: #666666;
            //line-height: 14px;
            text-align: center;
            table-layout:fixed;
            word-break: break-all;
          }
        }
        .share {
          cursor: default;
          display: none;
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
          left: -126px;
          width: 116px;
          background: #FFFFFF;
          box-shadow: 0px 4px 12px 0px rgba(127, 133, 141, 0.13);
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 8px;
          &:before {
            content: "";
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            right: -10px;
            height: 60px;
            width: 10px;
          }
          &:after {
            content: "";
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            right: -4px;
            height: 0px;
            width: 0px;
            border-top: 4px solid transparent;
            border-left: 4px solid #FFF;
            border-bottom: 4px solid transparent;
          }
          div {
            width: 100px;
            height: 100px;
            margin-top: 4px;
            /deep/ canvas {
              width: 100px !important;
              height: 100px !important;
            }
            /deep/ img {
              width: 100px !important;
              height: 100px !important;
            }
          }
          .copy {
            font-size: 14px;
            color: #0064EB;
            //line-height: 14px;
            cursor: pointer;
          }
          .code {
            font-size: 14px;
            color: #666666;
            //line-height: 14px;
            margin-top: 4px;
          }
        }
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 100%;
          height: 100%;
        }
        &:last-child {
          border-bottom: none;
        }
        i {
          font-size: 30px;
          color: #666666;
        }
        p {
          font-size: 14px;
          color: #666666;
        }
        &:hover {
          i {
            color: #0064EB;
          }
          p {
            color: #0064EB;
          }
        }
      }
      .download_box {
        &:hover {
          .download {
            display: flex;
          }
        }
      }
      .contact_box {
        &:hover {
          .contact {
            display: flex;
          }
        }
      }
      .share_box {
        &:hover {
          .share {
            display: flex;
          }
        }
      }
    }
  }
}
</style>

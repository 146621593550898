<template>
  <div class="crumbs">
    <ul class="crumbs_ul">
      <li v-for="item in config" :key="item.url">
        <a :href="item.url" v-if="item.url">{{ item.text }}</a>
        <span v-else>{{ item.text }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "crumbs",
  props: {
    config: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {};
  },
  created() {
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.crumbs {
  display: none;
}
@media (min-width: 751px) and (max-width: 960px) {
}
@media (min-width: 961px) {
  .crumbs {
    display: block;
    height: 72px;
    background: #FFFFFF;
    border-top: 1px solid #F5F5F5;
    border-bottom: 1px solid #F5F5F5;
    display: flex;
    align-items: center;
    .crumbs_ul {
      display: flex;
      width: 1200px;
      margin: 0 auto;
      li {
        font-size: 14px;
        color: #999999;
        line-height: 22px;
        margin-left: 8px;
        &:after {
          content: "/";
          margin-left: 8px;
          color: #999999;
          font-size: 14px;
        }
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          color: #666666;
          &:after {
            display: none;
          }
        }
        a {
          font-size: 14px;
          color: #999999;
          line-height: 22px;
        }
      }
    }
  }
}
</style>

<template>
  <div class="recommend" v-if="details.list && details.list.length>0">
    <p class="re_title">相关推荐</p>
    <ul class="re_ul">
      <li class="re_li" v-for="item in details.list" :key="item.moduleId">
        <a :href="`${path}?id=${item.moduleId}&moduleType=${moduleType}`" target="_blank">
          <img :src="utils.setOssUrl(item.picture)" alt="">
          <p>{{ item.title }}</p>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import utils from "@/utils/utils";

export default {
  name: "recommend",
  props: {
    details: {
      type: Object,
      default: () => {
        return {
          list: []
        };
      }
    }
  },
  data() {
    return {
      moduleType: "",
      path: "",
      utils
    };
  },
  created() {
    this.moduleType = this.$route.query.moduleType;
    switch (this.moduleType) {
      case "3":
        this.path = "/example/details";
        break;
      case "4":
        this.path = "/marketActivity/details";
        break;
      case "6":
        this.path = "/industryClass/details";
        break;
      default:
        this.path = "/industryClass/details";
    }
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.recommend {
  .re_title {
    font-size: 28px;
    color: #333333;
    line-height: 37px;
    margin-top: 48px;
    font-weight: bold;
  }
  .re_ul {
    .re_li {
      margin-top: 40px;
      a {
        display: inline-block;
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 312px;
        }
        p {
          font-size: 26px;
          color: #333333;
          line-height: 40px;
          margin-top: 22px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          max-height: 76px;
        }
      }
    }
  }
}
@media (min-width: 751px) and (max-width: 960px) {
  .recommend {
    .re_title {
      font-size: 28px;
      color: #333333;
      line-height: 37px;
      margin-top: 48px;
      font-weight: bold;
    }
    .re_ul {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      .re_li {
        margin-top: 30px;
        margin-left: 40px;
        width: 400px;
        &:nth-child(2n+1){
          margin-left: 0;
        }
        a {
          display: inline-block;
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 199px;
          }
          p {
            font-size: 24px;
            color: #333333;
            line-height: 36px;
            margin-top: 13px;
            word-break: break-all;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            max-height: 76px;
          }
        }
      }
    }
  }
}
@media (min-width: 961px) {
  .recommend {
    width: 360px;
    padding-left: 30px;
    .re_title {
      font-size: 18px;
      color: #333333;
      line-height: 24px;
      margin-top: 12px;
    }
    .re_ul {
      .re_li {
        margin-top: 24px;
        a {
          display: inline-block;
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 165px;
          }
          p {
            font-size: 14px;
            color: #333333;
            line-height: 24px;
            margin-top: 12px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            max-height: 52px;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="introduce" :style="styleObject">
    <p class="title">{{ title }}</p>
    <p class="desc hide">实现客户高效管理，客勤合理维护，数据智能分析</p>
  </div>
</template>

<script>
import {getPictures} from "@/api/common/index";
import utils from "@/utils/utils";

export default {
  name: "introduce",
  props: {
    moduleId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      styleObject: {},
      title: "纸包装行业销售管理工具"
    };
  },
  mounted() {
    this.getPicturesFun();
    this.setTitle();
  },
  methods: {
    setTitle() {
      switch (this.moduleId) {
        case 1:
          this.title = "免费试用";
          break;
        case 3:
          this.title = "客户案例";
          break;
        case 4:
          this.title = "市场活动";
          break;
        case 6:
          this.title = "产业课堂";
          break;
        case 7:
          this.title = "产品定价";
          break;
      }
    },
    getPicturesFun() {
      let params = {
        productId: this.$store.getters.getProductBasicInfo.productId
      };
      getPictures(params).then((res) => {
        if (res.success) {
          for (let i of res.data.list) {
            if (i.moduleType === this.moduleId && i.pictureType === 2) {
              this.styleObject = {
                backgroundImage: `url(${utils.setOssUrl(i.picture)})`
              };
              return;
            }
          }
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.introduce {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 422px;
  background-image: url("../../assets/images/bg_default.jpg");
  background-position: center;
  background-size: cover;
  .title {
    font-size: 56px;
    color: #FFFFFF;
    line-height: 78px;
    font-weight: bold;
  }
  .desc {
    font-size: 24px;
    color: #FFFFFF;
    line-height: 33px;
    margin-top: 24px;
  }
}
@media (min-width: 751px) and (max-width: 960px) {
  .introduce {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 540px;
    background-image: url("../../assets/images/bg_default.jpg");
    background-position: center;
    background-size: cover;
    .title {
      font-size: 48px;
      color: #FFFFFF;
      line-height: 67px;
      font-weight: bold;
    }
    .desc {
      font-size: 28px;
      color: #FFFFFF;
      line-height: 40px;
      margin-top: 24px;
    }
  }
}
@media (min-width: 961px) {
  .introduce {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 630px;
    background-image: url("../../assets/images/bg_default.jpg");
    background-position: center;
    background-size: cover;
    .title {
      font-size: 48px;
      color: #FFFFFF;
      line-height: 67px;
    }
    .desc {
      font-size: 28px;
      color: #FFFFFF;
      line-height: 40px;
      margin-top: 24px;
    }
  }
}
</style>

import esensors from "./sensorsInit";

export default new class sensors {
    officialSite_WatchVideo(eventObj) {
        esensors.track("officialSite_WatchVideo", eventObj);
    }

    officialSite_BrowseProduct(eventObj) {
        let source = "";
        switch (eventObj.source) {
            case "wechat":
                source = "公众号链接";
                break;
            case "qrcode":
                source = "扫描二维码";
                break;
            case "copyurl":
                source = "侧边栏-复制链接";
                break;
            case "more":
                source = "产品页-更多产品";
                break;
            case "footer":
                source = "产品页-底部栏";
                break;
            case "header":
                source = "产品页-顶部栏";
                break;
            case "siteProductCenter":
                source = "官网顶部栏-产品中心";
                break;
            case "siteProductSuggest":
                source = "官网首页产品介绍";
                break;
            default:
                source = "无前向入口";
                break;
        }
        let obj = {
            officialSite_e_browseProductSource: source,
            officialSite_e_title: eventObj.title
        };
        esensors.track("officialSite_BrowseProduct", obj);
    }

    officialSite_SubmitFreeUse() {
        esensors.track("officialSite_SubmitFreeUse");
    }

    officialSite_FreeUseDetails(eventObj) {
        let source = "";
        switch (eventObj.officialSite_e_freeUseDetailsSource) {
            case "indexModule":
                source = "产品页-免费试用";
                break;
            case "sidebar":
                source = "侧边栏-免费试用";
                break;
            case "footer":
                source = "底部栏-免费试用";
                break;
            case "qrcode":
                source = "扫描二维码";
                break;
            case "copyurl":
                source = "侧边栏-复制链接";
                break;
            default:
                source = "无前向入口";
                break;
        }
        let obj = {
            officialSite_e_freeUseDetailsSource: source,
            officialSite_e_productName: eventObj.officialSite_e_productName,
            officialSite_e_intentionType: eventObj.officialSite_e_intentionType,
            officialSite_e_isSuccess: eventObj.officialSite_e_isSuccess
        };
        console.log(obj);
        esensors.track("officialSite_FreeUseDetails", obj);
    }

    officialSite_Share(eventObj) {
        esensors.track("officialSite_Share", eventObj);
    }

    officialSite_Consult(eventObj) {
        esensors.track("officialSite_Consult", eventObj);
    }
};

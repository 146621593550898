<template>
  <div class="quillEditor">
    <div class="quill-editor">
      <div class="ql-container ql-snow">
        <div class="ql-editor" v-html="contents">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/quill/quill.core.css';
import '@/assets/quill/quill.snow.css';
import '@/assets/quill/quill.bubble.css';
import utils from "@/utils/utils";

export default {
  name: "quill",
  props: {
    content: {
      type: String,
      default: ""
    }
  },
  watch: {
    content(val) {
      this.contents = val;
      let re = /<img [^>]*src=['"]([^'"]+)[^>]*>/gi;
      // eslint-disable-next-line no-useless-escape
      let srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i // 匹配图片中的src
      let reHttp = new RegExp("http");
      let imgArr = this.contents.match(re);
      if (imgArr) {
        for (let i = 0; i < imgArr.length; i++) {
          let imgSrc = imgArr[i].match(srcReg);
          if (!reHttp.test(imgSrc[1])) {
            this.contents = this.contents.replaceAll(imgSrc[1], utils.setOssUrl(imgSrc[1]));
          }
        }
      }
    }
  },
  data() {
    return {
      contents: ''
    };
  },
  created() {
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.ql-editor {
  padding: 0;
}
.ql-container.ql-snow {
  border: none;
}
@media (min-width: 751px) and (max-width: 960px) {
}
@media (min-width: 961px) {
}
</style>

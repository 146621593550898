<template>
<div class="nothing">
  <img src="@/assets/images/nothing.png" alt="">
  <p>暂无信息</p>
</div>
</template>

<script>
export default {
name: "nothing",
data() {
    return {}
},
created()  {
},
methods: {}
}
</script>

<style scoped lang="scss">
.nothing{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img{
    width: 305px;
    height: 205px;
  }
  p{
    font-size: 32px;
    font-weight: 400;
    color: #666666;
    line-height: 45px;
    margin-top: 60px;
  }
}
@media (min-width: 751px) and (max-width: 960px) {
}
@media (min-width: 961px){
  .nothing{
    img{
      width: 305px;
      height: 255px;
    }
    p{
      font-size: 24px;
      font-weight: 400;
      color: #666666;
      line-height: 33px;
    }
  }
}
</style>

<template>
  <div class="mobileFooter" :class="{bigscreen:isBigScreen}">
    <a :href="sidebarInfo.consultLink" target="_blank" class="footer_consult" v-if="sidebarInfo.onlineConsult===1"
       :class="{footer_consult_one:sidebarInfo.tryNow===0&&sidebarInfo.contactUs===0&&sidebarInfo.freeUse===0}"
       @click="consult">
      <i class="font_family icon-floatbar_kefu"></i>
      <span>在线咨询</span>
    </a>
    <p class="footer_box" @click="experience" v-if="sidebarInfo.tryNow===1">
      <i class="font_family icon-floatbar_tiyan"></i>
      <span>立即体验</span>
    </p>
    <p class="footer_box" @click="contact" v-if="sidebarInfo.contactUs===1">
      <i class="font_family icon-floatbar_lianxi"></i>
      <span>联系我们</span>
    </p>
    <router-link to="/product/freeUse?source=footer" class="footer_box" v-if="sidebarInfo.freeUse===1">
      <i class="font_family icon-floatbar_shiyong"></i>
      <span>免费试用</span>
    </router-link>
  </div>
</template>

<script>
import utils from "@/utils/utils";

export default {
  name: "mobileFooter",
  props: {
    sidebarInfo: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      isBigScreen: false
    };
  },
  created() {
    this.getIsBigScreen();
  },
  methods: {
    getIsBigScreen() {
      window.onresize = () => {
        this.isBigScreen = utils.isBigScreen();
      };
      this.isBigScreen = utils.isBigScreen();
    },
    contact() {
      window.location.href = `tel:${this.sidebarInfo.contact}`;
    },
    experience(){
      window.open(this.sidebarInfo.tryLink)
    },
    consult(){
      // 在线咨询埋点
      this.$sensors.officialSite_Consult({
        officialSite_e_title:document.title
      })
    }
  }
};
</script>

<style scoped lang="scss">
.mobileFooter {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.94);
  border-top: 1px solid #F5F5F5;
  box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.05);
  .footer_consult{
    width: 135px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 71px;
    border-right: 1px solid #DBE1EA;
    i{
      font-size: 48px;
      color: #0064EB;
    }
    span{
      font-size: 20px;
      color: #666666;
      line-height: 28px;
    }
  }
  .footer_consult_one{
    flex-direction: row;
    width: 100%;
    height: 100%;
    border-right: none;
    span{
      margin-left: 24px;
      font-size: 32px;
      color: #252931;
    }
  }
  .footer_box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 98px;
    i {
      font-size: 48px;
      color: #0064EB;
    }
    span {
      font-size: 32px;
      color: #252931;
      margin-left: 20px;
      margin-top: -5px;
    }
  }
}
.bigscreen{
  height: 167px;
  padding-bottom: 67px;
}
@media (min-width: 750px) and (max-width: 960px) {
  .mobileFooter{
    .footer_consult{
      width: 96px;
    }
    .footer_consult_one{
      width: 100%;
    }
  }
}
@media (min-width: 961px) {
  .mobileFooter {
    display: none;
  }
}
</style>

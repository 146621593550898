<template>
  <div class="advert">
    <p class="title" v-if="adsPojo.adTitle">{{adsPojo.adTitle}}</p>
    <a v-if="adsPojo.adLink" :href="adsPojo.adLink" target="_blank" class="ad_img" :style="styleObject"></a>
    <a v-else class="ad_img" :style="styleObject"></a>
  </div>
</template>

<script>
import utils from "@/utils/utils";

export default {
  name: "advert",
  props: {
    adsPojo: {
      type: Object,
      default: () => {
        return {
          adTitle: "",
          adPicture: "",
          adLink: ""
        };
      }
    }
  },
  computed:{
    styleObject(){
      return {
        backgroundImage: `url(${utils.setOssUrl(this.adsPojo.adPicture)})`
      }
    }
  },
  data() {
    return {};
  },
  created() {
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.advert {
  border-top: 1px solid #EEEEEE;
  .title {
    font-size: 28px;
    font-weight: bold;
    color: #333333;
    line-height: 42px;
    padding: 32px 60px;
  }
  .ad_img {
    display: flex;
    background-image: url("../../assets/images/footer_bg.png");
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 280px;
  }
}
@media (min-width: 751px) and (max-width: 960px) {
  .advert {
    border-top: 1px solid #EEEEEE;
    .title {
      font-size: 26px;
      font-weight: bold;
      color: #333333;
      line-height: 40px;
      padding: 30px 60px;
    }
    .ad_img {
      display: flex;
      background-image: url("../../assets/images/footer_bg.png");
      background-position: center;
      background-size: cover;
      width: 100%;
      height: 280px;
    }
  }
}
@media (min-width: 961px) {
  .advert {
    border-top: 1px solid #EEEEEE;
    .title {
      font-size: 20px;
      font-weight: bold;
      color: #333333;
      line-height: 32px;
      padding: 30px 0;
      width: 1200px;
      margin: 0 auto;
    }
    .ad_img {
      display: flex;
      background-image: url("../../assets/images/footer_bg.png");
      background-position: center;
      background-size: cover;
      width: 100%;
      height: 280px;
    }
  }
}
</style>

<template>
  <div class="notfound">
    <img class="not_img" src="@/assets/images/404.png" alt="">
    <div class="not_con">
      <p class="con_text">你要找的页面不见了…</p>
      <router-link to="/" class="con_home" v-if="btnShow">返回首页</router-link>
    </div>
  </div>
</template>

<script>
import metaInfoMixins from "@/utils/mixin/metaInfoMixins";

export default {
  name: "notFound",
  mixins: [metaInfoMixins],
  data() {
    return {
      code: "",
      btnShow: true
    };
  },
  created() {
    this.code = this.$route.query.code;
    if (this.code === "001802002") {
      this.btnShow = false;
    }
  },
  methods: {},
  mounted() {
    this.$nextTick(() => {
      let title = document.title;
      if (title === "") {
        document.title = "页面未找到";
      }
    });
  }
};
</script>

<style scoped lang="scss">
.notfound {
  padding: 0 117px;
  .not_img {
    width: 100%;
    height: 447px;
    margin-top: 90px;
  }
  .not_con {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 90px;
    .con_text {
      font-size: 36px;
      color: #252931;
      line-height: 50px;
      margin-top: 72px;
    }
    .con_home {
      margin-top: 36px;
      width: 264px;
      height: 73px;
      background: #0064EB;
      font-size: 24px;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
@media (min-width: 750px) and (max-width: 960px) {
  .notfound {
    padding: 0 227px;
    .not_img {
      width: 100%;
      height: 447px;
      margin-top: 90px;
    }
    .not_con {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-bottom: 90px;
      .con_text {
        font-size: 36px;
        color: #252931;
        line-height: 50px;
        margin-top: 72px;
      }
      .con_home {
        margin-top: 36px;
        width: 264px;
        height: 73px;
        background: #0064EB;
        font-size: 24px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
@media (min-width: 961px) {
  .notfound {
    padding: 78px 0 53px 0;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    .not_img {
      width: 516px;
      height: 447px;
      margin-top: 0;
      margin-left: 67px;
    }
    .not_con {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding-bottom: 0;
      .con_text {
        font-size: 30px;
        color: #252931;
        line-height: 42px;
        margin-top: 0;
      }
      .con_home {
        margin-top: 27px;
        width: 160px;
        height: 49px;
        background: #0064EB;
        font-size: 16px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>

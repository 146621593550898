<template>
  <div class="confirm" v-if="show" @click.self="close">
    <div class="box">
      <p class="title">{{ config.title }}</p>
      <div class="btns">
        <p class="cancel" @click="cancel">{{ config.cancelText }}</p>
        <p class="define" @click="define">{{ config.defineText }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "confirm",
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          title: "",
          cancelText: "取消",
          defineText: "确认",
          cancelSuccess: () => {
            this.show = false;
          },
          defineSuccess: () => {
            this.show = false;
          },
        };
      }
    }
  },
  data() {
    return {
      show: false
    };
  },
  created() {

  },
  mounted() {

  },
  methods: {
    close() {
      this.show = false;
    },
    cancel() {
      if (typeof this.config.cancelSuccess === "function") {
        this.config.cancelSuccess(this.close);
      }else{
        this.close()
      }
    },
    define() {
      if (typeof this.config.defineSuccess === "function") {
        this.config.defineSuccess(this.close);
      }else{
        this.close()
      }
    }
  }
};
</script>

<style scoped lang="scss">
.confirm {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.55);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  .box {
    width: 560px;
    background: #FFFFFF;
    .title {
      font-size: 30px;
      color: #333333;
      line-height: 42px;
      padding: 40px 24px;
      border-bottom: 1px solid #E5E5E5;
      text-align: center;
    }
    .btns {
      display: flex;
      align-items: center;
      p {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        min-height: 98px;
        font-size: 36px;
        color: #1677FF;
      }
      .cancel {
        font-weight: 400;
      }
      .define {
        font-weight: 600;
      }
    }
  }
}
@media (min-width: 751px) and (max-width: 960px) {
}
@media (min-width: 961px) {
  .confirm {
    .box {
      width: 360px;
      box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
      border: 1px solid #E4E7ED;
      .title {
        font-size: 14px;
        font-weight: 500;
        color: #606266;
        line-height: 24px;
        padding: 20px 30px;
        border-bottom: none;
        text-align: center;
      }
      .btns {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 20px;
        p {
          flex: initial;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          min-height: 28px;
          font-size: 12px;
          color: #1677FF;
          width: 77px;
          height: 28px;
        }
        .cancel {
          font-weight: 400;
          background: #FFFFFF;
          border: 1px solid #DCDFE6;
          color: #5A5E66;
        }
        .define {
          font-weight: 500;
          background: #0064EB;
          color: #FFFFFF;
          margin-left: 10px;
        }
      }
    }
  }
}
</style>

let routes = [
    //市场活动-列表
    {
        path: '/marketActivity/list',
        name: 'marketActivityList',
        component: () => import('@/views/marketActivity/list.vue'),
    },
    //市场活动-详情
    {
        path: '/marketActivity/details',
        name: 'marketActivityDetails',
        component: () => import('@/views/marketActivity/details.vue'),
    },
];

export default routes;
